@import url('https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --body-color: #0a0a0a;
  --white-color: #FFFFFF;
  --text-color: #8e8e8e;
  --night-rider: #2b2b2b;
}

::-webkit-scrollbar {
  display: none;
}

::selection {
  user-select: none;
}

img {
  -webkit-user-drag: none;
  user-select: none;
}

a {
  text-decoration: none;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden !important;
}

body {
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: var(--body-color);
  overflow-x: hidden !important;
}
