/* Loader.css */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader {
  color: #fff;
  font-size: 3rem;
  text-align: center;
}

.percentage {
  animation: fadeIn 1s ease-in-out infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader path:nth-child(1) {
  stroke-dasharray: 200%;
  stroke-dashoffset: 200%;
  animation: strokeAnimate 1s 0s ease forwards;
}

#loader path:nth-child(2) {
  stroke-dasharray: 200%;
  stroke-dashoffset: 200%;
  animation: strokeAnimate 1s 0.1s ease forwards;
}

#loader path:nth-child(3) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.2s ease forwards;
}

#loader path:nth-child(4) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.3s ease forwards;
}

#loader path:nth-child(5) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.4s ease forwards;
}

#loader path:nth-child(6) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.5s ease forwards;
}

#loader path:nth-child(7) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.6s ease forwards;
}

#loader path:nth-child(8) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.7s ease forwards;
}

#loader path:nth-child(9) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.8s ease forwards;
}

#loader path:nth-child(10) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 0.9s ease forwards;
}

#loader path:nth-child(11) {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: strokeAnimate 1s 1s ease forwards;
}

@keyframes strokeAnimate {
  to {
    stroke-dashoffset: 0;
  }
}
.border--bottom {
  border-bottom: 1px solid var(--night-rider);
}

.border--right {
  border-right: 1px solid var(--night-rider);
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-40%);
  }
}
.login_spotify_button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #1ed760;
  color: #FFF;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 30px;
  border: none;
}
.login_spotify_button h6 {
  margin: 0;
}
.login_spotify_button svg {
  width: 24px;
  height: 24px;
}

.not_playing_song {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #1ed760;
  color: #FFF;
  padding: 10px 20px;
  font-weight: 500;
  border-radius: 30px;
  border: none;
}
.not_playing_song h6 {
  margin: 0;
}
.not_playing_song svg {
  width: 24px;
  height: 24px;
}

.about_section {
  padding-top: 150px;
  padding-bottom: 150px;
  background-image: url("../resources/images/asset_star_1.svg"), url("../resources/images/asset_star_2.svg");
  background-position: 121% 55%, 0 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 480px 480px, auto;
}
.about_section .hero-avatar-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}
.about_section .hero-avatar-wrap .hero-avatar-img-wrap {
  width: 120px;
  height: 120px;
  background-image: linear-gradient(rgba(55, 55, 55, 0), #2d2d2d);
  border: 1px solid #494949;
  border-radius: 8px;
  margin-bottom: 14px;
  padding: 10px;
  overflow: hidden;
}
.about_section .hero-avatar-wrap .hero-avatar-img-wrap .AsyncImage-root {
  height: 100%;
  border-radius: 8px;
}
.about_section .hero-avatar-wrap .hero-avatar-img-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 8px;
}
.about_section .hero-avatar-wrap .hero-avatar-name {
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: white;
}
.about_section .hero-title-wrap---about {
  text-align: center;
}
.about_section .hero-title-wrap---about .heading-01 {
  letter-spacing: -1.28px;
  font-size: 56px;
  font-weight: 500;
  line-height: 72px;
  margin: 0;
  color: #FFFFFF;
}
.about_section .hero-text---about {
  text-align: center;
  margin: 30px 0;
}
.about_section .hero-text---about h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-color);
  margin: 0;
}
.about_section .hero-button {
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
  justify-content: center;
  margin-bottom: 70px;
}
.about_section .hero-button .button-01-wrap---r {
  background-image: linear-gradient(to right, #444, rgba(68, 68, 68, 0));
  border-radius: 60px;
  padding: 1px;
  transition: 0.3s;
}
.about_section .hero-button .button-01-wrap---r:hover {
  opacity: 0.7;
}
.about_section .hero-button .button-01-wrap---r .button-01---r {
  background-image: linear-gradient(to right, #1e1e1e, rgba(0, 0, 0, 0));
  border-radius: 60px;
  padding: 8px 24px;
  color: white;
}

.scroll-info {
  position: relative;
  width: 100vw;
  background-color: #141414;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 15px 0;
  -webkit-mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 100px, #000 calc(100% - 100px), transparent);
          mask-image: linear-gradient(to right, #000, #000, transparent 0, #000 100px, #000 calc(100% - 100px), transparent);
}
.scroll-info .m-scroll {
  overflow: hidden;
  height: 100%;
  white-space: nowrap;
  animation: scrollText 20s infinite linear;
  margin: 0;
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
}
.scroll-info h2 {
  margin: 0;
  font-size: 18px;
  color: white;
  display: flex;
  -moz-column-gap: 50px;
       column-gap: 50px;
  text-transform: uppercase;
}
@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.skill-section {
  padding-top: 200px;
  padding-bottom: 140px;
}
.skill-section .skill-content {
  margin-bottom: 70px;
}
.skill-section .skill-content h2 {
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -0.56px;
  color: var(--white-color);
  margin: 0;
}
.skill-section .skill-list {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.skill-section .skill-list .skill-item {
  width: 100%;
  border: 1px solid var(--night-rider);
  border-radius: 18px;
  padding: 40px 32px;
  background-color: #141414;
}
.skill-section .skill-list .skill-item .skill-name {
  margin-top: 0;
  margin-bottom: 16px;
  display: inline-block;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #FFFFFF;
}
.skill-section .skill-list .skill-item p {
  color: var(--text-color);
  margin: 0;
}
.skill-section .skill-list .skill-item .skill-item-img-wrap {
  justify-content: center;
  margin-top: 40px;
  display: flex;
}
.skill-section .skill-list .skill-item .skill-item-img-wrap img {
  width: 100%;
  max-width: 220px;
}

.sort-info {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  margin-top: 40px;
  display: flex;
}
.sort-info .client-review {
  width: 100%;
  max-width: 477px;
  border: 1px solid var(--night-rider);
  border-radius: 14px;
  padding: 32px;
}
.sort-info .client-review .client-heading {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}
.sort-info .client-review .client-heading h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  color: #FFFFFF;
}
.sort-info .client-review .client-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-color);
}
.sort-info .reviews-card {
  width: 100%;
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  border: 1px solid var(--night-rider);
  background-color: #0a0a0a;
  border-radius: 14px;
  justify-content: space-between;
  align-items: center;
  padding: 36px 32px;
  display: flex;
}
.sort-info .reviews-card .review--about {
  width: 100%;
}
.sort-info .reviews-card .review--about .review-heading {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}
.sort-info .reviews-card .review--about .review-heading h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  color: #FFFFFF;
}
.sort-info .reviews-card .review--about .review-avatars {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 24px;
  display: flex;
  overflow: scroll;
  max-width: 350px;
}
.sort-info .reviews-card .review--about .review-avatars::-webkit-scrollbar {
  display: none;
}
.sort-info .reviews-card .review--about .review-avatars img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 1px solid #444;
}
.sort-info .reviews-card .rating--about {
  border-radius: 8px;
  padding: 28px 41px 28px 42px;
  background-color: #141414;
}
.sort-info .reviews-card .rating--about .rating-text {
  letter-spacing: -0.56px;
  margin-bottom: 0;
  font-size: 56px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 64px;
}

.recent-project {
  padding-bottom: 200px;
}
.recent-project .recent-project-text {
  text-align: center;
  margin-bottom: 100px;
}
.recent-project .recent-project-text h2 {
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -0.56px;
  color: var(--white-color);
  margin: 0;
  display: inline-block;
}
.recent-project .project-list {
  grid-column-gap: 70px;
  grid-row-gap: 70px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.recent-project .project-list .project-items {
  transition: 0.3s;
}
.recent-project .project-list .project-items:hover {
  transform: translateY(-20px);
}
.recent-project .project-list .project-items .custom-link {
  text-decoration: none;
}
.recent-project .project-list .project-items .custom-link .project-item {
  width: 100%;
  border: 1px solid var(--night-rider);
  border-radius: 14px;
  padding: 12px 12px 24px;
}
.recent-project .project-list .project-items .custom-link .project-item .project-thumb-wrap {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.recent-project .project-list .project-items .custom-link .project-item .project-thumb-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
}
.recent-project .project-list .project-items .custom-link .project-item h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  color: var(--white-color);
  margin-top: 24px;
  margin-bottom: 4px;
}
.recent-project .project-list .project-items .custom-link .project-item p {
  color: var(--text-color);
  margin: 0;
}

.complete-section .complete-content {
  border: 1px solid var(--night-rider);
  border-radius: 18px;
  display: flex;
}
.complete-section .complete-content .complete-text-wrap {
  width: 100%;
  border-right: 1px solid var(--night-rider);
  padding: 80px 60px;
}
.complete-section .complete-content .complete-text-wrap h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--white-color);
  margin-bottom: 40px;
}
.complete-section .complete-content .complete-text-wrap a {
  text-decoration: none;
  background-image: linear-gradient(to right, #1e1e1e, rgba(0, 0, 0, 0));
  border-radius: 60px;
  padding: 8px 24px;
  color: #fff;
  transition: 0.3s;
}
.complete-section .complete-content .complete-text-wrap a:hover {
  opacity: 0.7;
}
.complete-section .complete-content .complete-showcase {
  width: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.complete-section .complete-content .complete-showcase .complete-showcase-item {
  justify-content: center;
  align-items: center;
  display: flex;
}
.complete-section .complete-content .complete-showcase .complete-showcase-item.card-border {
  border-right: 1px solid var(--night-rider);
  border-bottom: 1px solid var(--night-rider);
}
.complete-section .complete-content .complete-showcase .complete-showcase-item .showcase-text-wrap {
  text-align: center;
}
.complete-section .complete-content .complete-showcase .complete-showcase-item .showcase-text-wrap p {
  color: var(--text-color);
  margin: 0;
}
.complete-section .complete-content .complete-showcase .complete-showcase-item .showcase-text-wrap h6 {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin: 0;
  color: #FFFFFF;
}

.testimonial-section {
  padding-top: 200px;
}
.testimonial-section .testimonial-title-wrap {
  position: relative;
}
.testimonial-section .testimonial-title-wrap h2 {
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -0.56px;
  color: var(--white-color);
  margin: 0;
  display: inline-block;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow {
  position: absolute;
  right: 0%;
  top: 50%;
  transform: translate(0%, -20%);
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .prev-button {
  width: 64px;
  height: 64px;
  background-color: #141414;
  border: none;
  border-radius: 50%;
  position: unset;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .prev-button.swiper-button-disabled {
  opacity: 0.5;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .prev-button::after {
  font-size: 20px;
  color: white;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .next-button {
  width: 64px;
  height: 64px;
  background-color: #141414;
  border: none;
  border-radius: 50%;
  position: unset;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .next-button.swiper-button-disabled {
  opacity: 0.5;
}
.testimonial-section .testimonial-title-wrap .navigation-arrow .next-button::after {
  font-size: 20px;
  color: white;
}
.testimonial-section .swiper {
  margin-top: 70px;
  overflow: unset;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
  border: 1px solid var(--night-rider);
  border-radius: 18px;
  background-color: #141414;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 450px;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body {
  padding: 0 32px;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap {
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar .avatar-placeholder {
  width: 72px;
  height: 72px;
  padding: 1px;
  border-radius: 50%;
  background-image: linear-gradient(to right, #141414, rgba(0, 0, 0, 0));
  border: 1px solid #444;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar .avatar-placeholder h6 {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar .AsyncImage-root {
  width: 72px;
  height: 72px;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  -o-object-fit: contain;
     object-fit: contain;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar h6 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0;
  color: var(--white-color);
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-role-wrap {
  color: var(--text-color);
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-ratings {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-top: 40px;
  margin-bottom: 32px;
  display: flex;
}
.testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-text {
  color: var(--white-color);
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.faqs-section {
  padding-top: 200px;
  padding-bottom: 200px;
}
.faqs-section .faqs-title {
  text-align: center;
}
.faqs-section .faqs-title h2 {
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: -0.56px;
  color: var(--white-color);
  margin: 0;
  display: inline-block;
}
.faqs-section .faqs-body {
  width: 100%;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}
.faqs-section .faqs-body .accordion {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.faqs-section .faqs-body .accordion .accordion-item {
  border: 1px solid var(--night-rider);
  background-color: #141414;
  border-radius: 8px;
}
.faqs-section .faqs-body .accordion .accordion-item .accordion-header button {
  background-color: transparent;
  box-shadow: none;
  color: var(--white-color);
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
}
.faqs-section .faqs-body .accordion .accordion-item .accordion-header button::after {
  background-image: url("../resources/images/down-arrow.svg");
  width: 28px;
  height: 28px;
  background-size: 28px;
}
.faqs-section .faqs-body .accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 0 20px;
}
.faqs-section .faqs-body .accordion .accordion-item .accordion-collapse .accordion-body p {
  color: var(--text-color);
}

.contact-us-section {
  padding-top: 140px;
  padding-bottom: 140px;
}
.contact-us-section .hero-content-wrap---contact {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  -moz-column-gap: 80px;
       column-gap: 80px;
}
.contact-us-section .hero-content-wrap---contact .hero-img-wrap---contact {
  width: 100%;
  max-width: 40%;
  border: 1px solid var(--night-rider);
  border-radius: 300px;
  padding: 10px;
  background-color: #141414;
}
.contact-us-section .hero-content-wrap---contact .hero-img-wrap---contact img {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact {
  width: 100%;
  max-width: 60%;
  text-align: center;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .heading-01 {
  letter-spacing: -1.28px;
  font-size: 56px;
  font-weight: 500;
  line-height: 72px;
  margin: 0;
  color: #FFFFFF;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .text-contact {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-items: center;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  justify-content: center;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .text-contact .contact-text-wrap {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  align-items: center;
  display: flex;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .text-contact .contact-text-wrap a {
  margin: 0;
  color: var(--white-color);
  font-weight: 500;
  text-decoration: none;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group {
  margin-bottom: 20px;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group:last-child {
  margin-top: 30px;
  margin-bottom: 0px;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group input,
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--night-rider);
  background-color: transparent;
  margin-bottom: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  color: var(--white-color);
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group input:focus,
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group textarea:focus {
  outline: none;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group .error-border {
  border-bottom: 1px solid red;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group .valid-border {
  border-bottom: 1px solid green;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group textarea {
  height: 100px;
  resize: none;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form .input-group .error {
  color: red;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form button {
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: 1px solid #444;
  border-radius: 60px;
  padding: 22px 24px;
  display: block;
  color: white;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form button .spinner-border {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 2px;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact form button:hover {
  opacity: 0.7;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .success-message {
  background-color: initial;
  border: 1px solid #444;
  border-radius: 60px;
  color: #fff;
  display: block;
  padding: 22px 24px;
  text-align: center;
  width: 100%;
}
.contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .success-message h3 {
  font-size: 20px;
  margin: 0;
}

.fourohfour_section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.fourohfour_section h1 {
  font-size: 150px;
  letter-spacing: 1px;
  font-weight: 700;
  color: black;
  margin: 0;
  margin-bottom: -40px;
  text-shadow: 2px 2px #fff, -2px 2px #fff, 2px -2px #fff, -2px -2px #fff, 2px 2px 5px #555;
}
.fourohfour_section h1 span {
  transform: rotate(270deg);
  display: inline-block;
}
.fourohfour_section h6 {
  font-size: 160px;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}
.fourohfour_section p {
  font-size: 20px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}
.fourohfour_section img {
  margin-bottom: 50px;
}
.fourohfour_section a {
  text-align: center;
  background-image: linear-gradient(to right, #141414, rgba(0, 0, 0, 0));
  border: 1px solid #444;
  border-radius: 60px;
  padding: 15px 40px;
  display: block;
  color: white;
  opacity: 1;
  transition: 0.3s;
}
.fourohfour_section a:hover {
  opacity: 0.7;
}

@media (max-width: 1600px) {
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: 480px;
  }
}
@media (max-width: 1400px) {
  .about_section .hero-title-wrap---about .heading-01,
  .skill-section .skill-content h2,
  .recent-project .recent-project-text h2,
  .testimonial-section .testimonial-title-wrap h2,
  .faqs-section .faqs-title h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .heading-01 {
    font-size: 46px;
    line-height: 60px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: 530px;
  }
}
@media (max-width: 1200px) {
  .sort-info .reviews-card .review--about .review-avatars {
    max-width: 200px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: 560px;
  }
}
@media (max-width: 1140px) {
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: 590px;
  }
}
@media (max-width: 991px) {
  .about_section .hero-title-wrap---about .heading-01,
  .skill-section .skill-content h2,
  .recent-project .recent-project-text h2,
  .testimonial-section .testimonial-title-wrap h2,
  .faqs-section .faqs-title h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .heading-01 {
    font-size: 40px;
    line-height: 50px;
  }
  .about_section,
  .skill-section,
  .contact-us-section {
    padding: 100px 0;
  }
  .recent-project {
    padding-bottom: 100px;
  }
  .testimonial-section {
    padding-top: 100px;
  }
  .faqs-section {
    padding: 100px 0 200px 0;
  }
  .skill-section .skill-list {
    grid-template-columns: 1fr 1fr;
  }
  .recent-project .project-list {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }
  .sort-info {
    flex-direction: column;
  }
  .sort-info .client-review {
    max-width: 100%;
  }
  .complete-section .complete-content {
    flex-direction: column;
  }
  .complete-section .complete-content .complete-text-wrap {
    padding: 60px 40px;
    border-bottom: 1px solid var(--night-rider);
    border-right: none;
  }
  .complete-section .complete-content .complete-showcase .complete-showcase-item {
    padding: 40px 0;
  }
  .contact-us-section .hero-content-wrap---contact {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-direction: column;
  }
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact {
    margin: 0;
  }
  .sort-info .reviews-card .review--about .review-avatars {
    max-width: 300px;
  }
  .contact-us-section .hero-content-wrap---contact .hero-img-wrap---contact {
    max-width: 50%;
  }
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact {
    max-width: 100%;
    padding: 0 16px;
  }
}
@media (max-width: 840px) {
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: 650px;
  }
}
@media (max-width: 767px) {
  .about_section,
  .skill-section {
    padding: 80px 0;
  }
  .recent-project {
    padding-bottom: 80px;
  }
  .testimonial-section {
    padding-top: 80px;
  }
  .skill-section .skill-list {
    grid-template-columns: 1fr;
  }
  .recent-project .project-list {
    grid-template-columns: 1fr;
  }
  .sort-info .reviews-card .rating--about {
    width: 100%;
    text-align: center;
  }
  .recent-project .recent-project-text {
    margin-bottom: 50px;
  }
  .about_section {
    background-size: 280px 280px, 280px 280px;
  }
  .testimonial-section .testimonial-title-wrap .navigation-arrow {
    position: unset;
    transform: unset;
    justify-content: center;
  }
  .testimonial-section .testimonial-title-wrap h2 {
    text-align: center;
    display: block;
    margin-bottom: 50px;
  }
  .testimonial-section .swiper {
    margin-top: 40px;
  }
  .faqs-section .faqs-body .accordion {
    margin-top: 60px;
  }
  .sort-info .reviews-card .review--about .review-avatars {
    max-width: 150px;
  }
  .recent-project .project-list .project-items .custom-link .project-item h3 {
    font-size: 24px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    height: auto;
  }
}
@media (max-width: 480px) {
  .about_section .hero-title-wrap---about .heading-01,
  .skill-section .skill-content h2,
  .recent-project .recent-project-text h2,
  .testimonial-section .testimonial-title-wrap h2,
  .faqs-section .faqs-title h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact h2,
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .heading-01 {
    font-size: 30px;
    line-height: 40px;
  }
  .about_section,
  .contact-us-section {
    padding: 70px 0;
  }
  .recent-project {
    padding-bottom: 70px;
  }
  .testimonial-section {
    padding-top: 70px;
  }
  .about_section .hero-button {
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
  }
  .sort-info .reviews-card {
    flex-direction: column;
    align-items: self-start;
  }
  .sort-info .reviews-card .review--about .review-avatars {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }
  .sort-info .reviews-card .rating--about {
    padding: 25px 35px;
  }
  .skill-section .skill-list .skill-item {
    padding: 30px;
  }
  .sort-info .reviews-card .rating--about .rating-text {
    font-size: 42px;
    line-height: 52px;
  }
  .recent-project .project-list {
    grid-row-gap: 20px;
  }
  .complete-section .complete-content .complete-text-wrap h3 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  .complete-section .complete-content .complete-text-wrap {
    padding: 40px 20px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-text {
    font-size: 16px;
    line-height: 24px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-ratings {
    margin: 20px 0;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card {
    padding: 30px 0;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body {
    padding: 0 30px;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap {
    flex-direction: column;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-owner-wrap .testimonial-owner-avatar {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
  }
  .testimonial-section .swiper .swiper-wrapper .swiper-slide .testimonial-card .testimonial-body .testimonial-ratings img {
    width: 20px;
  }
  .faqs-section .faqs-body .accordion .accordion-item .accordion-header button {
    padding: 15px;
  }
  .faqs-section .faqs-body .accordion {
    margin-top: 40px;
  }
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact h2 {
    margin-bottom: 50px;
  }
  .contact-us-section .hero-content-wrap---contact .hero-text-wrap---contact .text-contact {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  .sort-info .reviews-card .review--about .review-avatars {
    max-width: -moz-max-content;
    max-width: max-content;
  }
  .contact-us-section .hero-content-wrap---contact .hero-img-wrap---contact {
    max-width: 80%;
  }
  .fourohfour_section img {
    width: 300px;
  }
  .fourohfour_section p {
    font-size: 18px;
  }
  .login_spotify_button {
    width: 48px;
    height: 48px;
    justify-content: center;
    padding: 0;
  }
  .not_plying_song {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .login_spotify_button h6 {
    display: none;
  }
}
@media (max-width: 360px) {
  .sort-info .reviews-card .review--about .review-avatars {
    grid-template-columns: 1fr 1fr 1fr;
  }
  #loader svg {
    width: 270px;
  }
}/*# sourceMappingURL=Home.css.map */